import React from 'react'
import SEOTools from '../components/SEOTools'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { graphql } from 'gatsby'
import HeroUnit from '../components/HeroUnit'
import styled from 'styled-components'
import ContactForm from '../components/Forms/ContactForm'
import StyledSideCol from '../components/styled/StyledSideCol'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import Layout from '../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const ContactPage = ({ data }) => {
    const linkedInBgImage = getImage(data.linkedInBg)

    return (
        <Layout>
            <SEOTools
                title='Contact us'
                description='Have something on your mind? Tell us!'
                image={getSrc(data.heroBg.childImageSharp)}
            />

            <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay'>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Contact us</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section className='pt-5 pb-3'>
                <Container>
                    <Row className='text-center'>
                        <Col md='4' className='mb-4'>
                            <StyledCard>
                                <Card.Body className='d-flex align-items-center justify-content-center'>
                                    <p className='mb-0'>
                                        Kampinkuja 2
                                        <br />
                                        00100 Helsinki Finland
                                    </p>
                                </Card.Body>
                            </StyledCard>
                        </Col>
                        <Col md='4' className='mb-4'>
                            <StyledCard>
                                <Card.Body className='d-flex align-items-center justify-content-center'>
                                    <div>
                                        <p>
                                            Business:
                                            <br />
                                            <a href='mailto:sales@sirumobile.com'>sales@sirumobile.com</a>
                                        </p>
                                        <p className='mb-0'>
                                            Consumers:
                                            <br />
                                            <a href='mailto:support@sirumobile.com'>support@sirumobile.com</a>
                                        </p>
                                    </div>
                                </Card.Body>
                            </StyledCard>
                        </Col>
                        <Col md='4' className='mb-4'>
                            <StyledCard>
                                <Card.Body className='d-flex align-items-center justify-content-center'>
                                    <div>
                                        <p>
                                            Finland:
                                            <br />
                                            <a href='tel:+358974790630'>+358 9 7479 0630</a>
                                        </p>
                                        <p className='mb-0'>
                                            Other countries:
                                            <br />
                                            <a href='tel:+46812121205'>+46 8 121 21 205</a>
                                        </p>
                                    </div>
                                </Card.Body>
                            </StyledCard>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='pb-5'>
                <Container>
                    <Row className='mt-5 mb-4 pt-5'>
                        <StyledSideCol md='3' className='mb-3'>
                            <div className='text-secondary top'>Contact us</div>
                            <div className='h3 bottom'>
                                <strong>Ask a question</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                Questions, concerns, or feedback? Would like more information on something? Let us know
                                how we can help you and we will get back to you.
                            </p>
                        </Col>
                    </Row>

                    <ContactForm name='contact' />
                </Container>
            </section>

            <section className='dark-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0'>
                    <Container className='py-5 my-5'>
                        <Row className='d-flex justify-content-center'>
                            <Col xs='10'>
                                <h2>Want to partner with us?</h2>
                                <p className='mb-5 spaced-light'>
                                    At Siru Mobile we believe in the power of many. We're a recognized part of the
                                    business community and we actively take part in current events.
                                </p>
                                <a
                                    href='https://www.linkedin.com/company/siru-mobile/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='btn btn-success btn-lg px-5 rounded-0'>
                                    Connect on LinkedIn
                                </a>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={linkedInBgImage}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>
        </Layout>
    )
}

const StyledCard = styled(Card)`
    min-height: 13em;
`

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "sirumobile_contact_us.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
        linkedInBg: file(relativePath: { eq: "sirumobile_aboutus_linkedin.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default ContactPage
